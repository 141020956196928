import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

//layouts file
Vue.component("generic-payment-layout", () => import("@/components/layouts/frontend/GenericPaymentLayout.vue"));

//BootstrapVue
import { BootstrapVue} from 'bootstrap-vue'
Vue.use(BootstrapVue)

//Import vue-google-map-plugin
import * as VueGoogleMaps from "vue2-google-maps"
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAB1e2r8zgZIEb6c5U-d-Kikss-iMMiuRM",
        libraries: "places",
    },
});

//axios
import axios from 'axios';
Vue.prototype.$http = axios;

// Credit or Debit card validation
import VueCardFormat from 'vue-credit-card-validation';
Vue.use(VueCardFormat);

// Declare base url
Vue.prototype.$momobaseurl = process.env.VUE_APP_MOMO_URL;
Vue.prototype.$airtelbaseurl = process.env.VUE_APP_AIRTEL_URL;
Vue.prototype.$cardbaseurl = process.env.VUE_APP_CARD_URL;
Vue.prototype.$standappurl = process.env.VUE_APP_STANDAPP_URL;
Vue.prototype.$namecheckurl = process.env.VUE_APP_NAMECHECK_URL;
Vue.prototype.$locatebaseurl = process.env.VUE_APP_LOCATE_URL;

// Declare namecheck username password
Vue.prototype.$namecheckuser = process.env.VUE_APP_NAMECHECK_USERNAME;
Vue.prototype.$namecheckpwd = process.env.VUE_APP_NAMECHECK_PWD;

const routes= [
    //front routes
    {
        path: '',
        redirect: '/payment',
    },

    {
        path: '/payment',
        name: 'generic-payment',
        component: require('@/components/frontend/generic-payment/MoneyTransferBy.vue').default,
        meta: {title: "Payment Transfer", layout:'generic-payment'},
    },
    
    {
        path: '/payment',
        redirect: '/payment/payment-options',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        children: [
            // { path: 'payment-options',component: require('@/components/frontend/generic-payment/MoneyTransferBy.vue').default, meta: { title: "Payment Transfer", layout:'generic-payment'}},
            { path: 'payment-transfered',component: require('@/components/frontend/generic-payment/PaymentTransfer.vue').default, meta: { title: "Payment Receive", layout:'generic-payment'}},
        ]
    },

    {
        path: '/success',
        name: 'success',
        component: require('@/components/common/SuccessComponent.vue').default,
        meta: {title: "Success", layout:'generic-payment'},
    },
    {
        path: '/failure',
        name: 'failure',
        component: require('@/components/common/FailureComponent.vue').default,
        meta: {title: "Failure", layout:'generic-payment'},
    },
    {
        path: '/access-denied',
        name: '403',
        component: require('@/components/common/403Component.vue').default,
        meta: {title: "Forbidden", layout:'generic-payment'},
    },
    {
        path: '/session-expired',
        name: 'session-expired',
        component: require('@/components/common/ExpiredComponent.vue').default,
        meta: {title: "Expired", layout:'generic-payment'},
    },

    //ui-kit routes
    {
        path: '/admin/uikit',
        component: () => import('@/components/admin/uikit/RouterView.vue'),
        meta: { breadCrumb: 'Ui Kit'},
        children: [
            { path: 'forms-validation', component: require('@/components/admin/uikit/ValidationComponent.vue').default, meta: { breadCrumb: "Form Validation", title: "Form Validation" }, },
            { path: 'forms-basic', component: () => import('@/components/admin/uikit/BasicFormComponent.vue'), meta: { breadCrumb: "Basic Form Elements",title: "Basic Form Elements"  },},
        ]
    },
    {
        path: '*',
        redirect: '/payment'
    }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  base: ''
})

router.afterEach((to) => {
    Vue.nextTick( () => {
        document.title = ':: PayAngel :: '+ to.meta.title;
    });
})
Vue.config.performance = true
Vue.config.productionTip = false
new Vue({
  router:router,
  render: h => h(App),
}).$mount('#app')