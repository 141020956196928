<template>
  <div class="row clearfix justify-content-center flex-sm-row-reverse">
    <div class="col-xl-3 col-lg-4 col-md-4" v-if="pageshow">
      <!--  v-if="pageshow" -->
      <div class="card bg-gradient text-white p-4 text-center">
        <div class="bg-white mx-auto mb-3 rounded-circle right-logo">
          <img :src="merchantLogo"
            alt="Company Logo"
            class="img-fluid"
          />
          <!-- src="@/assets/img/send-money/company-logo.png" -->
        </div>
        <h6 class="font-22 fw-400 mb-0">{{this.eventtitle}}</h6>
        <p class="font-18 fw-400 mb-2 mt-4">Total to Pay</p>
        <h4 class="font-40 fw-500 mb-0" v-if="this.amount!=''">{{this.currency}} {{this.amount}}</h4>
      </div>
    </div>
    <div class="col-xl-5 col-lg-8 col-md-8 mb-4" v-if="pageshow">
      <div class="card full-border mb-0">
        <form id="campaign-form" ref="form">
          <div class="body pt-4">
            <!-- <ValidationProvider
              name="selectcountry"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="country-dropdown mt-1">
                <div
                  class="form-group form-select multiselect-grp"
                  :class="{
                    errors: errors[0],
                    success:
                      checkout_country &&
                      checkout_country.length != 0 &&
                      !errors[0],
                  }"
                >
                  <span class="float-label">
                    <label for="Country">Select Country*</label>
                    <multiselect
                      placeholder=""
                      v-model="checkout_country"
                      :show-labels="false"
                      :options="countrysOptions"
                      :searchable="false"
                      label="name"
                      track-by="name"
                      @select="emitData"
                    >
                      <template slot="option" slot-scope="props">
                        <span :class="props.option.flagClass"></span>
                        <div class="option__desc">
                          <span class="option__title">{{
                            props.option.name
                          }}</span>
                        </div>
                      </template>
                    </multiselect>
                    <i
                      class="fa fa-times"
                      v-if="errors.length && errors[0]"
                    ></i>
                    <i
                      class="fa fa-check"
                      v-if="
                        checkout_country &&
                        checkout_country.length != 0 &&
                        !errors[0]
                      "
                    ></i>
                  </span>
                </div>
              </div>
            </ValidationProvider> -->
            <div class="row">
              <div class="col-sm-6" v-if="currency == 'GHS'"> 
                <!-- v-if="countryCode == 'GH'" -->
                <!--  v-if="checkout_country.primaryNumberCode == 'GH'" -->
                <div
                  class="payment-card p-3 d-flex justify-content-between align-items-center mt-3 mt-sm-0"
                  :class="{ active: transferby == 'mobile-money' }"
                  @click="payby('mobile-money')"
                >
                  <div class="left d-flex align-items-center">
                    <div class="payment-icon mr-3">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          class="fill"
                          d="M22.5 1.875C22.9973 1.875 23.4742 2.07254 23.8258 2.42417C24.1775 2.77581 24.375 3.25272 24.375 3.75V26.25C24.375 26.7473 24.1775 27.2242 23.8258 27.5758C23.4742 27.9275 22.9973 28.125 22.5 28.125H7.5C7.00272 28.125 6.52581 27.9275 6.17417 27.5758C5.82254 27.2242 5.625 26.7473 5.625 26.25V3.75C5.625 3.25272 5.82254 2.77581 6.17417 2.42417C6.52581 2.07254 7.00272 1.875 7.5 1.875H22.5ZM7.5 0C6.50544 0 5.55161 0.395088 4.84835 1.09835C4.14509 1.80161 3.75 2.75544 3.75 3.75V26.25C3.75 27.2446 4.14509 28.1984 4.84835 28.9017C5.55161 29.6049 6.50544 30 7.5 30H22.5C23.4946 30 24.4484 29.6049 25.1516 28.9017C25.8549 28.1984 26.25 27.2446 26.25 26.25V3.75C26.25 2.75544 25.8549 1.80161 25.1516 1.09835C24.4484 0.395088 23.4946 0 22.5 0L7.5 0Z"
                        />
                        <path
                          class="fill"
                          d="M10.9092 13.7188C11.0549 15.3596 12.3985 16.5241 14.4439 16.6747V17.8717H15.4706V16.6747C17.705 16.4985 19.091 15.2592 19.091 13.4264C19.091 11.8613 18.1588 10.9557 16.1813 10.4458L15.4706 10.2618V6.5193C16.575 6.62758 17.3202 7.22212 17.5082 8.10999H18.9552C18.7918 6.53505 17.4393 5.40503 15.4706 5.28002V4.09094H14.4439V5.30463C12.5353 5.53103 11.2252 6.75358 11.2252 8.4112C11.2252 9.84243 12.176 10.8553 13.8445 11.2825L14.4449 11.442V15.4098C13.3139 15.2425 12.5353 14.6224 12.3473 13.7188H10.9092ZM14.2471 9.94283C13.2204 9.68395 12.6721 9.13075 12.6721 8.35214C12.6721 7.42293 13.3651 6.73684 14.4439 6.55277V9.99303L14.2471 9.94382V9.94283ZM15.8132 11.7855C17.08 12.1035 17.6361 12.6311 17.6361 13.5268C17.6361 14.6066 16.8231 15.3262 15.4706 15.4354V11.6999L15.8132 11.7845V11.7855Z"
                        />
                        <path
                          class="fill"
                          d="M15 26.25C15.4973 26.25 15.9742 26.0525 16.3258 25.7008C16.6775 25.3492 16.875 24.8723 16.875 24.375C16.875 23.8777 16.6775 23.4008 16.3258 23.0492C15.9742 22.6975 15.4973 22.5 15 22.5C14.5027 22.5 14.0258 22.6975 13.6742 23.0492C13.3225 23.4008 13.125 23.8777 13.125 24.375C13.125 24.8723 13.3225 25.3492 13.6742 25.7008C14.0258 26.0525 14.5027 26.25 15 26.25Z"
                        />
                      </svg>
                    </div>
                    <p class="mb-0">Mobile Money</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6" v-if="currency == 'GBP' || currency == 'AUD' || currency == 'EUR' || currency == 'CAD'">
                <div
                  class="payment-card p-3 d-flex justify-content-between align-items-center mt-3 mt-sm-0"
                  :class="{ active: transferby == 'pay-by-card' }"
                  @click="payby('pay-by-card')"
                >
                  <div class="left d-flex align-items-center">
                    <div class="payment-icon mr-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"
                        />
                        <path
                          d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"
                        />
                      </svg>
                    </div>
                    <p class="mb-0">Pay by Card</p>
                  </div>
                </div>
              </div>
              <!-- <div class="col-sm-4">
                <div
                  class="payment-card p-3 d-flex justify-content-between align-items-center mt-3 mt-sm-0"
                  :class="{ active: transferby == 'pay-by-bank' }"
                  @click="payby('pay-by-bank')"
                >
                  <div class="left d-flex align-items-center">
                    <div class="payment-icon mr-3">
                      <svg
                        width="22"
                        viewBox="0 0 22 22"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 0.306152L20.0887 4.4999H21.3125C21.4948 4.4999 21.6697 4.57234 21.7986 4.70127C21.9276 4.8302 22 5.00507 22 5.1874V7.9374C22 8.11974 21.9276 8.29461 21.7986 8.42354C21.6697 8.55247 21.4948 8.6249 21.3125 8.6249H20.625V18.2499C20.7784 18.2499 20.9273 18.3013 21.0481 18.3957C21.169 18.4901 21.2548 18.6222 21.2919 18.771L21.9794 21.521C22.0046 21.6224 22.0065 21.7281 21.9848 21.8303C21.9631 21.9324 21.9184 22.0283 21.8541 22.1106C21.7899 22.1929 21.7077 22.2595 21.6138 22.3053C21.52 22.3511 21.4169 22.3749 21.3125 22.3749H0.6875C0.58307 22.3749 0.48002 22.3511 0.386169 22.3053C0.292319 22.2595 0.210136 22.1929 0.145857 22.1106C0.0815784 22.0283 0.0368939 21.9324 0.0151949 21.8303C-0.00650397 21.7281 -0.00464697 21.6224 0.020625 21.521L0.708125 18.771C0.745237 18.6222 0.831033 18.4901 0.95187 18.3957C1.07271 18.3013 1.22164 18.2499 1.375 18.2499V8.6249H0.6875C0.505164 8.6249 0.330295 8.55247 0.201364 8.42354C0.0724328 8.29461 0 8.11974 0 7.9374V5.1874C0 5.00507 0.0724328 4.8302 0.201364 4.70127C0.330295 4.57234 0.505164 4.4999 0.6875 4.4999H1.91125L11 0.306152ZM5.192 4.4999H16.8066L11 1.81865L5.192 4.4999ZM2.75 8.6249V18.2499H4.125V8.6249H2.75ZM5.5 8.6249V18.2499H8.9375V8.6249H5.5ZM10.3125 8.6249V18.2499H11.6875V8.6249H10.3125ZM13.0625 8.6249V18.2499H16.5V8.6249H13.0625ZM17.875 8.6249V18.2499H19.25V8.6249H17.875ZM20.625 7.2499V5.8749H1.375V7.2499H20.625ZM20.0887 19.6249H1.91125L1.5675 20.9999H20.4325L20.0887 19.6249Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <p class="mb-0">Pay by Bank</p>
                  </div>
                </div>
              </div> -->

              <mobile-money
                :checkout_country="checkout_country"
                v-if="transferby == 'mobile-money' && currency == 'GHS'"
              ></mobile-money>
              <!--  && checkout_country.primaryNumberCode == 'GH' -->
                <!-- :merchant_details="merchant_details" -->

              <pay-by-card
                v-if="transferby == 'pay-by-card' && currency == 'GBP' || currency == 'AUD' || currency == 'EUR' || currency == 'CAD' || currency == 'USD'"
                :checkout_country="checkout_country"
              ></pay-by-card>

              <pay-by-bank v-if="transferby == 'pay-by-bank'"></pay-by-bank>
              
              <div class="my-4 pt-3 d-flex justify-content-center w-100" v-if="!CurrencyArr.includes(currency)">
                <span class="badge-danger">Currently this currency is not supported.</span>
              </div>
              <div class="my-4 pt-3 d-flex justify-content-center w-100">
                <img
                  src="@/assets/img/logo-powered-by.png"
                  alt="PayAngel Logo"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <main-loader ref="loader"></main-loader>
  </div>
</template>
<script>
// const { ValidationProvider } = require("vee-validate");
// const CryptoJS = require("crypto-js");
const LoaderComponent = require("@/components/loader/LoaderComponent.vue").default;
export default {
  name: "MoneyTransferBy",
  components: {
    // ValidationProvider,
    // Multiselect: () => import("vue-multiselect"),
    "main-loader": LoaderComponent,
    "mobile-money": () =>
      import(
        "@/components/frontend/generic-payment/money-transfer-options/MobileMoney.vue"
      ),
    "pay-by-card": () =>
      import(
        "@/components/frontend/generic-payment/money-transfer-options/PaybyCard.vue"
      ),
    "pay-by-bank": () =>
      import(
        "@/components/frontend/generic-payment/money-transfer-options/PaybyBank.vue"
      ),
  },
  data() {
    return {
      pageshow:false,
      transferby: "mobile-money",
      CurrencyArr : ['GHS','GBP','AUD','EUR' ,'CAD','USD'],
      countrysOptions: [
        // {
        //   name: "United Kingdom",
        //   flagClass: "flag-icon flag-icon-gb fi-xl fi-round",
        //   primaryNumberCode: "GB",
        //   id:"8e60ff5a-dda4-45eb-b220-9c4dfcdb32a5"
        // },
        // {
        //   name: "Ghana",
        //   flagClass: "flag-icon flag-icon-gh fi-xl fi-round",
        //   primaryNumberCode: "GH",
        //   id:"8ee2b557-1047-49c4-bac2-ee7bf35e24e3"
        // },
      ],
      checkout_country: {},
      // merchant_details: null,

      merchantLogo:'',
      eventtitle:'',
      currency:'',
      amount:'',
      countryCode:''
    };
  },
  methods: {
    emitData(item) {
      this.checkout_country = item;
      if(item.primaryNumberCode == 'GH'){
        this.transferby = "mobile-money";
      }else{
        this.transferby = "pay-by-card";
      }
    },
    payby(paybyIs) {
      this.transferby = paybyIs;
    },

    checkUrl(){
      //if user want direct access payment page then show access denied page otherwise proceed for payment
      if(this.$route.query.token){
        this.$refs.loader.show();
        // Get contents value from url
        const getToken = this.$route.query.token;

        this.$http
        .get(this.$standappurl + "payment/merchant-details/" + getToken)
        .then((res) => {
          if(res.data.code == '0150-000') {
            const merchantDetail = res.data.details;
            const businessDetail = JSON.parse(merchantDetail.businessdata);
            // console.log(businessDetail);
            this.eventtitle = businessDetail.eventtitle;
            this.merchantLogo = businessDetail.merchantlogo;
            this.currency = merchantDetail.currency;
            // console.log(this.currency);
            this.amount = parseFloat(merchantDetail.amount).toFixed(2);
            //Store required data at sessionstorage
            sessionStorage.setItem('settranid',merchantDetail.transactionid);
            sessionStorage.setItem('amt',this.amount);
            sessionStorage.setItem('currency',this.currency);
            sessionStorage.setItem('success',merchantDetail.success_url);
            sessionStorage.setItem('error',merchantDetail.failure_url);
            sessionStorage.setItem('merchanturl',businessDetail.merchanturl);

            sessionStorage.setItem('countrycode',merchantDetail.country);

            this.countryCode = merchantDetail.country.toUpperCase();
            if(merchantDetail.currency == 'GHS'){
              this.transferby = "mobile-money";
            } else if(merchantDetail.currency == 'GBP' || merchantDetail.currency == 'AUD' || merchantDetail.currency == 'EUR' || merchantDetail.currency == 'CAD' || merchantDetail.currency == 'USD'){
              this.transferby = "pay-by-card";
            } else {
              this.transferby = "pay-by-card";
            }



            const userDetail = {
              firstname: businessDetail.firstname ? businessDetail.firstname : '',
              middlename: businessDetail.middlename ? businessDetail.middlename : '',
              lastname: businessDetail.lastname ? businessDetail.lastname : '',
              address1 : businessDetail.address1 ? businessDetail.address1 : '',
              address2 : businessDetail.address2 ? businessDetail.address2 : '',
              city : businessDetail.city ? businessDetail.city : '',
              state : businessDetail.state ? businessDetail.state : '',
              zip : businessDetail.zip ? businessDetail.zip : '',
              email : businessDetail.email ? businessDetail.email : '',
              phonenumber : businessDetail.phonenumber, // ? businessDetail.dialcode.split("+").join("")+businessDetail.phonenumber.split("+").join("") : '',
              // phonecode : businessDetail.dialcode ? businessDetail.dialcode.split("+").join("") : '',
              ipaddress : merchantDetail.ipaddress,
              reference : merchantDetail.reference
            };
            // console.log(userDetail);
            sessionStorage.setItem("userData", JSON.stringify(userDetail));
            
            this.pageshow = true;
            this.$refs.loader.hide();
          }
        })
        .catch((error) => {
          // console.log(error.response);
          if(error.response.data.code == 'E0150-007' || error.response.data.code == 'E0150-008'){
            this.$router.push({ path: '/session-expired' });
            this.$refs.loader.hide();
          }
        });
      }else {
        this.$router.push({ path: '/access-denied' });
        // console.log('error page');
      }
    },

    async getCountryData() {
      try {
        const response = await this.$http.get(this.$standappurl + "countries/source");
        // console.log(response);
        this.countrysOptions = [];
        // JSON responses are automatically parsed.
        response.data.details.map((item) => {
          if(item.countryname == 'Ghana'){
            this.checkout_country = {
              name: item.countryname,//"Ghana",
              flagClass: "flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
              primaryNumberCode: item.isocode,//"GH",
              id: item.countryid //"8ee2b557-1047-49c4-bac2-ee7bf35e24e3"
            };
            sessionStorage.setItem('countryid',item.countryid);
          }

          let countryData = {
            id:item.countryid,
            name: item.countryname, 
            flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
            primaryNumberCode:item.isocode,
            alpha2:item.alpha2,
            alpha3:item.alpha3,
            currency:item.currencycode,
            mobilecode: item.mobilecountrycode
          }
          this.countrysOptions.push(countryData);
        })
        // console.log(this.countrysOptions);
      } catch (error) {
        console.log(error);
      }
    },    
  },
  mounted() {
    // this.checkout_country = {
    //   name: "Ghana",
    //   flagClass: "flag-icon flag-icon-gh fi-xl fi-round",
    //   primaryNumberCode: "GH",
    //   id: "8ee2b557-1047-49c4-bac2-ee7bf35e24e3"
    // };
    // sessionStorage.setItem('country','8ee2b557-1047-49c4-bac2-ee7bf35e24e3');

    this.getCountryData();
    this.checkUrl();

  },
};

// disable right click
document.addEventListener("contextmenu", (event) => event.preventDefault());

document.onkeydown = function (e) {
  // disable F12 key
  if (e.keyCode == 123) {
    return false;
  }
};
</script>
