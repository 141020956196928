<template>
  <div id="wrapper">
    <!-- v-bind:class="{ 'wrapper landing-page': landingClassListV2,
    'auth':authClassListV2, 'wrapper landing-page standapp-main':
    standappClassListV2, 'auth standapp-main': authStandappClassListV2,
    'auth-wrapper landing-page auth':authClassList || authStandappClassList,
    'standapp':authStandappClassList, 'customer-page':customerHomeClassList,
    'customer-auth':customerHomeAuthClassList}" -->
    <component :is="layout">
      <router-view></router-view>
    </component>
  </div>
</template>
<script>
const default_layout = "default";
export default {
  name: "App",
  watch: {
    $route: {
      immediate: true, // also trigger handler on initial value
      handler() {
        if (this.$route.name === "maintanance") {
          document.body.className = "";
        } else {
          document.body.className = "theme-turquoise";
        }
      },
    },
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },

  },
  mounted() {
    if (
      this.layout == "generic-payment-layout"
    ) {
      const head = document.body.parentElement.firstElementChild;
      const link = document.createElement("link");
      link.setAttribute("href", require("@/assets/scss/frontend.css"));
      link.setAttribute("rel", "stylesheet");
      head.appendChild(link);
    }
  },
};
</script>
<style lang="scss">
@import "../src/assets/vendor/bootstrap/css/bootstrap.min.css";
@import "../src/assets/vendor/font-awesome/css/font-awesome.min.css";
@import "../src/assets/scss/main.scss";
@import "../src/assets/scss/color_skins.scss";
@import "../node_modules/flag-icon-css/css/flag-icon.min.css";
</style>