<template>
  <div class="row clearfix justify-content-center" @shown="resetTimer">
    <div class="col-xl-7 col-lg-8 col-md-10">
      <div class="card payment-success-card full-border">
        <div class="card-body">
          <div class="transfers-list payment-summary-card">
            <div class="d-flex justify-content-center align-items-center flex-column">
              <img src="@/assets/img/cross.svg" alt="" />
              <h4 class="my-4 text-danger font-weight-normal">
                Payment Failure
              </h4>
            </div>
            <ul class="list-unstyled price-summary">
              <li>
                <div class="d-flex justify-content-between">
                  <p class="mb-0">Transaction ID</p>
                  <label class="font-weight-bold">{{ tranid }}</label>
                </div>
              </li>
              <li>
                <div class="d-flex justify-content-between">
                  <p class="mb-0">Amount</p>
                  <label class="font-weight-bold">{{ amount }}</label>
                </div>
              </li>
              <li>
                <p class="text-center">You are being redirected to the merchant. Please don't close this page. If the page does not redirect , click on the Ok button</p>
                <div class="d-flex flex-column flex-md-row justify-content-center mt-5" >
                    <button class="btn btn-lg btn-gradient btn-mw ml-0 ml-md-1 mt-1 mt-md-0" @click="paymentok()">
                        Ok
                    </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const FULL_DASH_ARRAY = 283;
const TIME_LIMIT = 10;
export default {
  name: "FailureComponent",
  data() {
    return {
      tranid:'',
      amount:'',
      failedReason: '',

      timePassed: 0,
      timerInterval: null
    };
  },
  methods: {
    paymentok(){
      const failureurl = sessionStorage.getItem('error');
      const tranid = sessionStorage.getItem('settranid');
      const encodetranid = btoa(tranid); 
      const redirecturl = failureurl+'?contents='+encodetranid;
      sessionStorage.removeItem("settranid");
      sessionStorage.removeItem('amt');
      sessionStorage.removeItem("currency");
      sessionStorage.removeItem('success');
      sessionStorage.removeItem('error');
      sessionStorage.removeItem('country');
      window.location.href = redirecturl;
      // this.$router.push({ path: failureurl });
    },

    pageRedirect() {
       console.log('page redirect');
      const failureurl = sessionStorage.getItem('error');
      const tranid = sessionStorage.getItem('settranid');
      const encodetranid = btoa(tranid); 
      const redirecturl = failureurl+'?contents='+encodetranid;
      sessionStorage.removeItem("settranid");
      sessionStorage.removeItem('amt');
      sessionStorage.removeItem("currency");
      sessionStorage.removeItem('success');
      sessionStorage.removeItem('error');
      sessionStorage.removeItem('country');
      window.location.replace(redirecturl);
    },

    onTimesUp() {
        clearInterval(this.timerInterval);
    },

    startTimer() {
        this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },

    //reset timer 
    resetTimer() {
        this.startTimer();
    },

    createLog(transactionid){
      let addLog = {
        transactionid : transactionid,
        pagename : 'Payment Failure'
      }
      this.$http.post(this.$standappurl + "payment/create/activitylog", addLog).then((response) => {
        console.log(response.data.code);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, mounted(){
    if(sessionStorage.getItem("settranid") && sessionStorage.getItem("settranid") != null){
      console.log('start mount',sessionStorage.getItem("settranid"));
      this.tranid = sessionStorage.getItem("settranid");
      this.amount = sessionStorage.getItem("currency")+' '+sessionStorage.getItem("amt")
      this.createLog(sessionStorage.getItem("settranid"));
      // this.$http
      //   .get(this.$standappurl + "payment/details/" + this.tranid)
      //   .then((res) => {
      //     console.log(res.data);
      //     const paymentResponse = JSON.parse(res.data.details.paymentResponse);
      //     console.log(paymentResponse);
      //     this.failedReason = paymentResponse.actions[0].response_summary;
      //     console.log(this.failedReason);
      //   })
      //   .catch((error) => {
      //     console.log(error.response);
      //   });
    } else {
      console.log('session clear');
      this.$router.push({ path: '/session-expired' });
    }
    
    // setTimeout(this.pageRedirect(), 15000);
    
  }
  ,computed: {
    circleDasharray() {
        return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
    },

    formattedTimeLeft() {
        const timeLeft = this.timeLeft;
        const minutes = Math.floor(timeLeft / 60);
        let seconds = timeLeft % 60;

        if (seconds < 10) {
            seconds = `0${seconds}`;
        }
        
        if( `${minutes}:${seconds}` == '0:00'){
            this.pageRedirect()
        }
        return `${minutes}:${seconds}`;
    },

    timeLeft() {
        return TIME_LIMIT - this.timePassed;
    },

    timeFraction() {
        const rawTimeFraction = this.timeLeft / TIME_LIMIT;
        return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
    }
  },watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp();
      }
    }
  }
};
</script>