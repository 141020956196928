<template>
    <div class="row clearfix justify-content-center">
        <div class="col-xl-7 col-lg-8 col-md-10">
            <div class="card payment-success-card full-border">
                <div class="card-body py-5">
                    <div class="payment-summary-card">
                        <div class="d-flex justify-content-center align-items-center flex-column">
                            <h4 class="mb-4 text-apple-green font-weight-normal">Session Expired!</h4>
                            <p>Your payment session is expired.</p>
                            <div class="d-flex">
                                <!-- <a @click="$router.go(-1)" class="btn btn-outline-dark"><i class="fa fa-arrow-left"></i> <span>Go Back</span></a> -->
                                <button @click="gomerchant()" class="btn btn-apple-green ml-1"> <i class="fa fa-home"></i> <span>Go to Merchant</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'ExpiredComponent',
    methods:{
        gomerchant(){
            window.location.href = sessionStorage.getItem('merchanturl');
        },

        createLog(transactionid){
            let addLog = {
                transactionid : transactionid,
                pagename : 'Payment Session Expired'
            }
            this.$http.post(this.$standappurl + "payment/create/activitylog", addLog).then((response) => {
                console.log(response.data.code);
            })
            .catch((error) => {
                console.log(error);
            });
        }
    },
    mounted(){
        if(sessionStorage.getItem('settranid')){
            this.createLog(sessionStorage.getItem('settranid'));
            sessionStorage.removeItem("settranid");
            sessionStorage.removeItem('amt');
            sessionStorage.removeItem("currency");
            sessionStorage.removeItem('success');
            sessionStorage.removeItem('error');
            sessionStorage.removeItem('country');
        }
    }
}
</script>